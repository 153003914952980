// https://codesandbox.io/s/react-hooks-counter-demo-dbr34?file=/src/index.js
// https://github.com/d3/d3-time-format
// https://nivo.rocks/pie/
import React from "react"
import PropTypes from "prop-types"

import {ResponsivePie} from '@nivo/pie'
//
// const data = [
//                 {
//                     "id": "c",
//                     "label": "c",
//                     "value": 249,
//                     "color": "hsl(230, 70%, 50%)"
//                 },
//                 {
//                     "id": "javascript",
//                     "label": "javascript",
//                     "value": 496,
//                     "color": "hsl(309, 70%, 50%)"
//                 },
//                 {
//                     "id": "sass",
//                     "label": "sass",
//                     "value": 67,
//                     "color": "hsl(39, 70%, 50%)"
//                 },
//                 {
//                     "id": "lisp",
//                     "label": "lisp",
//                     "value": 395,
//                     "color": "hsl(75, 70%, 50%)"
//                 },
//                 {
//                     "id": "stylus",
//                     "label": "stylus",
//                     "value": 182,
//                     "color": "hsl(352, 70%, 50%)"
//                 }
//             ];


const styles = {
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    height: 500,
    width: "100%"
};

// React mounting component lifecycle is:
//
// - constructor
// - componentWillMount
// - render
// - componentDidMount
// See more here: https://reactjs.org/docs/react-component.html#mounting
class AddressBalancesPie extends React.Component {

    constructor(props) {
        console.log('constructor');

        super(props);
        this.state = {
            isFetching: false,
            data: []
        };
    }

    fetchDataWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        console.log('foo');
        console.log(this.props.dataUrl);

        fetch(this.props.dataUrl)
            .then(response => response.json())
            .then(result => {
                console.log("Setting JSON State");
                this.setState({data: result, isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    componentDidMount() {
        console.log("Component mounted");
        this.fetchDataWithFetchAPI();
    };

    render() {
        const {data} = this.state
        return (
            <div style={styles}>
                <ResponsivePie
                    data={data}
                    margin={{ top: 10, right: 50, bottom: 20, left: 50 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    startAngle={-90}
                    endAngle={90}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={2}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}

                    arcLinkLabelsSkipAngle={15}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}


                    // legends={[
                    //     {
                    //         anchor: 'bottom',
                    //         direction: 'row',
                    //         justify: false,
                    //         translateX: 0,
                    //         translateY: 56,
                    //         itemsSpacing: 0,
                    //         itemWidth: 100,
                    //         itemHeight: 18,
                    //         itemTextColor: '#999',
                    //         itemDirection: 'left-to-right',
                    //         itemOpacity: 1,
                    //         symbolSize: 18,
                    //         symbolShape: 'circle',
                    //         effects: [
                    //             {
                    //                 on: 'hover',
                    //                 style: {
                    //                     itemTextColor: '#000'
                    //                 }
                    //             }
                    //         ]
                    //     }
                    // ]}

                    // tile={"squarify"}
                    //identity="id"
                    //value="value"
                    // valueFormat=".03s"
                    // leavesOnly={true}
                    // enableParentLabel={false}
                    // innerPadding={0}
                    // outerPadding={0}
                    //color={{scheme: "dark2"}}/
                    //colors={{ scheme: 'yellow_green' }} // https://nivo.rocks/guides/colors/
                    //borderColor={{ from: 'color' }}
                    // width={"100"}
                    // margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    // labelSkipSize={12}
                    // labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
                    // parentLabelTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                    // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.1 ] ] }}
                />
            </div>
        );
    };
}

AddressBalancesPie.propTypes = {
    greeting: PropTypes.string,
    dataUrl: PropTypes.string
};
export default AddressBalancesPie
