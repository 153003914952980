// https://codesandbox.io/s/react-hooks-counter-demo-dbr34?file=/src/index.js
// https://github.com/d3/d3-time-format
// https://nivo.rocks/pie/
import React from "react"
import PropTypes from "prop-types"

import {ResponsiveSunburst} from '@nivo/sunburst'

//
// const data = [
//                 {
//                     "id": "c",
//                     "label": "c",
//                     "value": 249,
//                     "color": "hsl(230, 70%, 50%)"
//                 },
//                 {
//                     "id": "javascript",
//                     "label": "javascript",
//                     "value": 496,
//                     "color": "hsl(309, 70%, 50%)"
//                 },
//                 {
//                     "id": "sass",
//                     "label": "sass",
//                     "value": 67,
//                     "color": "hsl(39, 70%, 50%)"
//                 },
//                 {
//                     "id": "lisp",
//                     "label": "lisp",
//                     "value": 395,
//                     "color": "hsl(75, 70%, 50%)"
//                 },
//                 {
//                     "id": "stylus",
//                     "label": "stylus",
//                     "value": 182,
//                     "color": "hsl(352, 70%, 50%)"
//                 }
//             ];


const styles = {
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    height: 700,
    width: "100%"
};

// React mounting component lifecycle is:
//
// - constructor
// - componentWillMount
// - render
// - componentDidMount
// See more here: https://reactjs.org/docs/react-component.html#mounting
class PortfolioSunburstChart extends React.Component {

    constructor(props) {
        console.log('constructor');

        super(props);
        this.state = {
            isFetching: false,
            data: []
        };
    }

    fetchDataWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        console.log('foo');
        console.log(this.props.dataUrl);

        fetch(this.props.dataUrl)
            .then(response => response.json())
            .then(result => {
                console.log("Setting JSON State");
                this.setState({data: result, isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    componentDidMount() {
        console.log("Component mounted");
        this.fetchDataWithFetchAPI();
    };

    render() {
        const {data} = this.state
        return (
            <div style={styles}>
                <ResponsiveSunburst
                    data={data}
                    id="id"
                    value="value"
                    // key={"value"}
                    // indexBy={"portfolio"}
                    // margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    // layout={"vertical"}
                    // groupMode={"stacked"}
                    // valueScale={{ type: 'log' }}
                    // maxValue={150000}
                    // colors={{ scheme: 'nivo' }}
                    cornerRadius={10}
                    // borderColor={{theme: 'background'}}
                    borderWidth={5}
                    colors={{scheme: 'accent'}}
                    colorBy={"id"}
                    inheritColorFromParent={false}
                    // childColor={{
                    //     // from: 'color',
                    //     modifiers: [
                    //         [
                    //             'brighter',
                    //             0.5
                    //         ]
                    //     ]
                    // }}
                    enableArcLabels={true}
                    // arcLabel={e => e.id + " (" + e.value + ")"}
                    arcLabel={e => e.id + ' - CHF ' + e.value + " (" + e.formattedValue + ")"}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.4
                            ]
                        ]
                    }}
                    tooltip={({ data: { id, label, value, color } }) => (
                        <span style={{ color }}>
                            <strong>{id}</strong>
                            <strong>{label}</strong>
                            <br/>
                            <strong>{value} CHF</strong>
                        </span>
                    )}
                    // tooltip={e =>
                    //     t.createElement(l, {style: {color: e.color}},
                    //         t.createElement(u, null, "id"),
                    //         t.createElement(c, null, e.id),
                    //         t.createElement(u, null, "value"),
                    //         t.createElement(c, null, e.value),
                    //         t.createElement(u, null, "percentage"),
                    //         t.createElement(c, null, Math.round(100 * e.percentage) / 100, "%"),
                    //         t.createElement(u, null, "color"),
                    //         t.createElement(c, null, e.color)
                    //     )
                    // }

                />
            </div>
        );
    };
}

PortfolioSunburstChart.propTypes = {
    greeting: PropTypes.string,
    dataUrl: PropTypes.string
};
export default PortfolioSunburstChart
