// https://codesandbox.io/s/react-hooks-counter-demo-dbr34?file=/src/index.js
// https://github.com/d3/d3-time-format
// https://nivo.rocks/treemap/
import React from "react"
import PropTypes from "prop-types"

import {ResponsiveTreeMap} from '@nivo/treemap'

const styles = {
    // fontFamily: "sans-serif",
    // fontSize: "14px",
    // textAlign: "center",
    height: 150,
    width: "100%"
};

// React mounting component lifecycle is:
//
// - constructor
// - componentWillMount
// - render
// - componentDidMount
// See more here: https://reactjs.org/docs/react-component.html#mounting
class AddressesTreeMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            data: {}
        };
    }

    fetchDataWithFetchAPI = () => {
        this.setState({...this.state, isFetching: true});
        fetch(this.props.dataUrl)
            .then(response => response.json())
            .then(result => {
                // console.log("Setting JSON State");
                this.setState({data: result, isFetching: false})
            })
            .catch(e => {
                console.log(e);
                this.setState({...this.state, isFetching: false});
            });
    };

    componentDidMount() {
        // console.log("Component mounted");
        this.fetchDataWithFetchAPI();
    };

    render() {
        const {data} = this.state
        return (
            <div style={styles}>
                <ResponsiveTreeMap
                    data={data}
                    // tile={"squarify"}
                    //identity="id"
                    //value="value"
                    valueFormat=".03s"
                    leavesOnly={true}
                    // enableParentLabel={false}
                    innerPadding={0}
                    outerPadding={0}
                    //color={{scheme: "dark2"}}/
                    //colors={{ scheme: 'yellow_green' }} // https://nivo.rocks/guides/colors/
                    //borderColor={{ from: 'color' }}
                    // width={"100"}
                    // margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    labelSkipSize={12}
                    // labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
                    // parentLabelTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                    // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.1 ] ] }}
                />
            </div>
        );
    };
}

AddressesTreeMap.propTypes = {
    greeting: PropTypes.string,
    dataUrl: PropTypes.string
};
export default AddressesTreeMap
