// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
import "@hotwired/turbo-rails";

import "trix"
import "@rails/actiontext"

// Our Client Side Stimulus/Turbo Controllers
import "./controllers"
import "./boostrap/bootstrap_js_modules"

// REACT
import "react"
import "react_ujs"

import "./components/WalletsTreeMap"
import "./components/AddressesTreeMap"
import "./components/AddressBalancesTreeMap"
import "./components/AddressBalancesPie"
import "./components/PortfolioSunburstChart"

//mport "components"
// import "./components/DonateUsk"
// import "./components/AddressesBalanceTreeMap"
///import "./components/PhaserBlockAnimation" // GAME !!

// import "./components/TerraCoreSwapsLiveBurner"

// Support component names relative to this directory:
// https://github.com/reactjs/react-rails#ujs


// https://turbo.hotwired.dev/handbook/drive#disabling-turbo-drive-on-specific-links-or-forms
// If you want Drive to be opt-in rather than opt-out, then you can set Turbo.session.drive = false; then,
// data-turbo="true" is used to enable Drive on a per-element basis.
// If you’re importing Turbo in a JavaScript pack, you can do this globally:
Turbo.session.drive = false

// Start RAILS ujs
Rails.start();